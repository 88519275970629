.sidebar-nav .nav-link.active {
  border-width: 0px 5px 0px 0px;
  border-color: $primary;
  border-style: solid;
}

.nav-underline {
  border-bottom: $nav-underline-border-width solid $nav-underline-border-color;

  .nav-link {
    border-bottom: $nav-underline-border-width solid transparent;
    color: $nav-underline-link-color;
  }

  .nav-link.login.active {
    color: black;
    border-color: black;
  }

  .nav-link.active {
    background: transparent;
    color: $nav-underline-link-active-color;
    border-color: $nav-underline-link-active-border-color;
  }

  .nav-item {
    margin-bottom: -$nav-underline-border-width;
  }
}

.login-topBar {
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  height: 103px;
  width: 100%;
  background: 'white';
}

.login-tab {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rotate-arrow {
  transition: transform 0.35s ease;

  &.active {
    transform: rotate(180deg);
  }
}

.page-item {
  &:first-child:not(.disabled),
  &:last-child:not(.disabled) {
    background-color: $white;
  }

  &:first-child,
  &:last-child {
    .page-link {
      padding: $pagination-padding-y 1.5rem;
    }
  }
}

.carousel-indicators {
  left: 0;
  top: auto;
  bottom: -45px;
}

.carousel-indicators [data-coreui-target]:not(.active) {
  width: 15px;
  height: 15px;
}

.carousel-indicators li {
  border-radius: 50%;
}

.carousel-indicators .active {
  background: $primary;
  width: 20px;
  height: 0px;
  margin-top: 7px;
}

.nav-link.no-hover {
  &:hover {
    color: $sidebar-nav-link-color;
    text-decoration: none;
    background-color: $sidebar-nav-link-bg;
  }
}

.cardImg {
  background-image: url(../assets/images/visa.svg);
  position: relative;

  img {
    position: absolute;
    background: red;
  }
}

.icon-home {
  color: $primary;
  font-weight: bold;
}

.reservation-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

}

.toast-container {
  z-index: 999999999;
}

.multi-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white !important;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.30)';
  // border: 1px solid rgb(183, 169, 169);
  gap: 10px;
  z-index: 999999998;
  // backdrop-filter: blur(10px);
  input,
  select {
    background: transparent;
  }

  pointer-events: all;
}


.buildingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white !important;
  width: 200px;
  height: 150px;
  padding: 20px;
  border-radius: 8px;

}

a {
  text-decoration: none;
  cursor: pointer;
}

.resume-item {
  background-color: unset;
  border-bottom: unset;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.resume-container{
  max-height: 60vh;
  overflow-y: auto;
}
